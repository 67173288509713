import React, {Component} from 'react';
import Aux from '../../hoc/Aux/Aux';
import ProjectPlaceholder from '../../components/UI/Placeholder/ProjectPlaceholder/ProjectPlaceholder'
import Gallery from '../../components/Gallery/Gallery';
import GalleryItem from '../../components/Gallery/GalleryItem/GalleryItem';
import ProjectLogo from '../../components/ProjectLogo/ProjectLogo';
import ShareButton from '../../components/UI/SocialShareButton/SocialShareButton';
import ProjectLogoPlaceholder from '../../components/UI/Placeholder/ProjectLogoPlaceholder/ProjectLogoPlaceholder';

import {Row, Col} from 'reactstrap';
import axios from '../../axios-base';
import moment from 'moment';
import SMSVote from "./SMSVote";
import FingerprintVote from "./FingerprintVote";
import VotePlaceholder from './VotePlaceholder';
import {Helmet} from "react-helmet";

class Project extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadedPost: null
        };
    }

    componentDidMount() {
        const { loadedPost } = this.state;
        const { match } = this.props;
        const { id } = match.params;

        if (id) {
            // @todo : implement Optional chaining for cleaner nested property comparison
            if (!loadedPost || (loadedPost && loadedPost.id !== id)) {
                axios.get(`/projects/${id}`)
                    .then(({data}) => this.setState({loadedPost: data}))
                    .catch(error => {
                        if (error.response) {
                            // The request was made and the server responded with a status code
                            // that falls out of the range of 2xx
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            // The request was made but no response was received
                            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                            // http.ClientRequest in node.js
                            console.log(error.request);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.log('Error', error.message);
                        }
                        console.log(error.config);
                    });

            }
        }
    }

    render() {
        let projectVoteCount = '-';
        let voteComponent;

        if (this.state.loadedPost) {
            const startDate = this.props.votingPhases.voteStart;
            if (!this.props.votingPhases.voteStarted) {
                // placeholder - prior to voting
                let text = 'Am ' + moment(startDate).format('DD.MM.') + ' geht\'s los';
                voteComponent = <VotePlaceholder text={text} />;
            } else if (this.props.votingPhases.voteEnded) {
                // placeholder - end of voting
                voteComponent = <VotePlaceholder text='Abstimmung ist beendet!' />;
            } else {
                // voting in progress
                if (this.props.votingPhases.type.length > 1) {

                    // if finale started
                    if (!this.props.votingPhases.voteStartedFinale) {
                        voteComponent = <FingerprintVote { ...this.props}/>;
                    } else {
                        // the finale has started
                        if (this.state.loadedPost.isFinalist) {
                            voteComponent = <SMSVote projectCategory={this.state.loadedPost.projectCategory} { ...this.props} />;
                        } else {
                            voteComponent = <VotePlaceholder text='Leider nicht im Finale' />;
                        }
                    }
                } else {
                    if (this.props.votingPhases.type[0].phaseType === 'fingerprint'){
                        console.log("Fingerprint is loaded, ", this.props.votingPhases.type[0])
                        // Global Fingerprint contest
                        voteComponent = <FingerprintVote { ...this.props}/>;
                    } else {
                        //Global SMS contest
                        voteComponent = <SMSVote projectCategory={this.state.loadedPost.projectCategory} { ...this.props}/>;
                    }
                }
            }

            if(this.props.votingPhases.type.length > 1){
                if (this.props.votingPhases.voteStartedFinale) {
                    if (this.state.loadedPost.voteCountFinale && this.state.loadedPost.isFinalist) {
                        projectVoteCount = this.state.loadedPost.voteCountFinale;
                    }
                } else {
                    if (this.state.loadedPost.voteCount) {
                        //console.log('vote count geladen...');
                        projectVoteCount = this.state.loadedPost.voteCount;
                    }
                }
            } else {
                projectVoteCount = this.state.loadedPost.voteCountFinale;
            }
        }

        let project = (
            <Row className="py-3">
                <Col xs="12">
                    <ProjectPlaceholder/>
                </Col>
            </Row>
        );

        if (this.state.loadedPost) {

            project = (
                <Aux>
                    <Helmet>
                        <title>{`${process.env.REACT_APP_PROJECT_TITLE}: ${this.state.loadedPost.contestantName}`}</title>
                    </Helmet>
                    <Row className="my-3">
                        <Col xs="12" lg="5">
                            <Row className="py-3">
                                <Col>
                                    {this.state.loadedPost.projectLogo ?
                                        <ProjectLogo
                                            src={this.state.loadedPost.projectLogo}
                                            alt={this.state.loadedPost.title + ' Logo'}/>
                                        : <ProjectLogoPlaceholder/>}
                                </Col>
                                <Col className="d-flex flex-wrap align-self-center text-center">
                                    <h6 className="text-uppercase w-100">Stimmen</h6>
                                    <h1 className="w-100">{projectVoteCount}</h1>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h1 className="text-primary">{this.state.loadedPost.contestantName}</h1>
                                    <h2 className="text-dark">{this.state.loadedPost.projectTitle}</h2>

                                </Col>
                            </Row>
                            <Row>
                                {voteComponent}
                                <Col xs="12" sm="6" lg="12" className="mb-2">
                                    <Row>
                                        <Col xs="6" lg="4" className="pb-1 pe-1 ps-3 pt-0 ps-md-1 ps-lg-3">
                                            <ShareButton shareType="facebook"/>
                                        </Col>

                                        <Col xs="6" lg="4" className="pb-1 pe-3 ps-1 pt-0 pe-md-1 pe-lg-3 d-md-none">
                                            <ShareButton
                                                shareTitle={this.state.loadedPost.contestantName}
                                                shareType="whatsApp" />
                                        </Col>

                                        <Col xs="6" lg="4" className="pb-1 pe-1 ps-3 pt-0 ps-md-1">
                                            <ShareButton
                                                shareTitle={this.state.loadedPost.contestantName}
                                                shareType="email"/>
                                        </Col>

                                        <Col xs="6" lg="4" className="pb-1 pe-3 ps-1 pt-0 ps-md-1 pe-lg-3">
                                            <ShareButton
                                                shareTitle={this.state.loadedPost.contestantName}
                                                shareType="twitter"/>
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>

                        </Col>

                        <Col xs="12" lg="7">
                            <Row>
                                <Col xs={{size: 12, order: 2}} lg={{order: 1}}>
                                    <h1 className="text-primary">Unser Gemeindeprojekt:</h1>
                                    <p>{this.state.loadedPost.contestantAnswer1}</p>
                                </Col>

                                <Col xs={{size: 12, order: 3}} lg={{order: 2}}>
                                    <h1 className="text-primary">Kurzbeschreibung des Projekts:</h1>
                                    <p>{this.state.loadedPost.contestantAnswer2}</p>
                                </Col>

                                <Col xs={{size: 12, order: 3}} lg={{order: 3}}>
                                    <h1 className="text-primary">Über unsere Gemeinde:</h1>
                                    <p>{this.state.loadedPost.contestantAnswer3}</p>
                                </Col>

                                <Col xs={{size: 12, order: 1}} lg={{order: 3}} className="px-0 p-md-3">
                                    <Gallery>
                                        {this.state.loadedPost.projectGallery.map((galleryItem, index) => (
                                            <GalleryItem
                                                key={index}
                                                src={galleryItem}
                                                alt={'Galeriebild ' + index}/>
                                        ))}
                                    </Gallery>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Aux>
            )
        }

        return (
            <Aux>
                {project}
            </Aux>
        );
    }
}

export default Project;
